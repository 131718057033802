<template>
	<div class="mt-1">
		<v-row>
			<v-col cols="4">
				<v-file-input
					:disabled="disabled"
					v-model="initialUploaded.upload_picture"
					label="Upload Pictures"
					multiple
					prepend-icon="mdi-paperclip"
					dense
					outlined	
					>
					<template v-slot:selection="{ text, index }">
						<v-chip
							small
							close
							@click:close="removeFile(index, 'upload_picture')"
							>
							{{ text }}
						</v-chip>
					</template>
				</v-file-input>
				<v-list>
					<v-list-item v-for="(item, index) in existingInitialUploaded.upload_picture" :key="index">
						<v-list-item-content>
							{{item.title}}
						</v-list-item-content>
						<v-list-item-action>
							<v-icon @click="retrieveUploaded(item.url)">
								mdi-eye
							</v-icon>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-col>
			
			<v-col cols="4">
				<v-file-input
					:disabled="disabled"
					v-model="initialUploaded.upload_loi"
					label="LOI File"
					multiple
					dense
					outlined
					prepend-icon="mdi-paperclip"
					>
					<template v-slot:selection="{ text, index }">
						<v-chip
							close
							small
							@click:close="removeFile(index, 'upload_loi')"
							>
							{{ text }}
						</v-chip>
					</template>
				</v-file-input>
				<v-list>
					<v-list-item v-for="(item, index) in existingInitialUploaded.upload_loi" :key="index">
						<v-list-item-content>
							{{item.title}}
						</v-list-item-content>
						<v-list-item-action>
							<v-icon @click="retrieveUploaded(item.url)">
								mdi-eye
							</v-icon>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-col>
							
			<v-col cols="4">
				<v-file-input
					:disabled="disabled"
					v-model="initialUploaded.upload_supp_docs"
					label="Upload Supporting Documents"
					multiple
					dense
					outlined
					prepend-icon="mdi-paperclip"	
					>
					<template v-slot:selection="{ text, index }">
						<v-chip
							close
							small
							@click:close="removeFile(index, 'upload_supp_docs')"
							>
							{{ text }}
						</v-chip>
					</template>
				</v-file-input>
				<v-list>
					<v-list-item v-for="(item, index) in existingInitialUploaded.upload_supp_docs" :key="index">
						<v-list-item-content>
							{{item.title}}
						</v-list-item-content>
						<v-list-item-action>
							<v-icon @click="retrieveUploaded(item.url)">
								mdi-eye
							</v-icon>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	props: ['disabled'],
    computed: {
		...mapGetters({
            salesProspectingForm: 'salesProspecting/salesProspectingForm',
			initialUploaded: 'salesProspecting/initialUploaded',
			existingInitialUploaded: 'salesProspecting/existingInitialUploaded'
		}),
	},
	methods: {
		removeFile (index, property) {
			this.initialUploaded[property].splice(index, 1)
		},
		async retrieveUploaded (item) {
			const payload = {
				file: item
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			console.log(res)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		}
	}
};
</script>
