<template>
	<div class="mt-1">
		<v-row>
			<v-col cols="4">	
				<v-menu
					v-model="menu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					min-width="auto"
					>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="salesProspectingForm.date_presentation"
                            label="Date of Presentation"
							prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
							v-on="disabled ? '' : on"
							dense
							outlined
							:rules="genericRule"
							hide-details="auto"
                            >
                        </v-text-field>
					</template>
					<v-date-picker
						v-model="salesProspectingForm.date_presentation"
						@input="menu = false"
                        >
                    </v-date-picker>
				</v-menu>
			</v-col>
		</v-row>
		
        <v-row>
			<v-col cols="12">
				<b>Documents Presented</b>
			</v-col>
		</v-row>
						
        <v-radio-group row> 
			<v-checkbox
				v-model="salesProspectingForm.documents_presented"
                v-for="(item, index) in docsPresentedChoices"
                :key="index"
				:label="item"
                :value="item"
                :class="index > 0 ? 'ml-2' : ''"
				color="primary"
				:hide-details="index > 0 ? true : false"
				:rules="checkboxRule"
				:readonly="disabled"
                >
            </v-checkbox>
		</v-radio-group>
						
        <v-row class="mt-2">
			<v-col cols="11">
				<v-text-field
					label="Summary of Presentation"
					v-model="salesProspectingForm.summary_presentation"
					outlined
					dense
					:rules="genericRule"
					:readonly="disabled"
					>
                </v-text-field>
			</v-col>
		</v-row>
		
        <v-radio-group
			v-model="salesProspectingForm.accept_or_negotiate"
			row
			>
			<v-radio
                v-for="(item, index) in acceptOrNegoChoices"
                :key="index"
				:label="item"
				:value="item"
				:rules="checkboxRule"
				:readonly="disabled"
                >
            </v-radio>	
		</v-radio-group>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	props: ['disabled'],
    computed: {
		...mapGetters({
            salesProspectingForm: 'salesProspecting/salesProspectingForm',
			genericRule: 'salesProspecting/genericRule',
			checkboxRule: 'salesProspecting/checkboxRule'
		}),
	},
    data: () => ({ 
		acceptOrNegoChoices: ['Accepts Partnership', 'For Negotiation', 'Does Not Accept Partnership', 'For Further Presentation'],
        docsPresentedChoices: ['Remco Sales Presentation', 'Non Ex REMCO Sales Presentation', 'AVP Presentation', 'T&C'],
		menu: false
	}),
};
</script>
